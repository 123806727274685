export const calculateTop = el => {
    const header = document.getElementById('fixed');
    const cabinetFixed = document.getElementById('cabinet_fixed');
    let top = header.offsetHeight;

    if (cabinetFixed !== null) {
        top += cabinetFixed.offsetHeight;
    }

    const bannerWrap = document.getElementById('topBanner');
    if (bannerWrap === null) {
        el.style.top = `${top}px`;
        return;
    }

    top += (bannerWrap.offsetHeight - window.scrollY > -1) ? bannerWrap.offsetHeight - window.scrollY : 0;
    el.style.top = `${top}px`;
}