class CartButtonHandler {
    constructor() {
        this.process = false;
        this.isInit = false;
    }

    init() {
        if (!this.isInit) {
            this.runButtons = document.querySelectorAll('[data-cart-btn="1"]');
            this.onHandlerRunButtonClick = this.handlerRunButtonClick.bind(this);
            this.miniCartWrap = document.getElementById('miniCartWrap');

            this.messageWrap = document.getElementById('cartMessageWrap');
            this.messageButtonWrap = document.getElementById('cartMessageButtons');
            this.messageButtonGoToCabinetWrap = document.getElementById('cartMessageGoToCabinet');
            this.messageButtonGoToCabinetLink = document.getElementById('cartMessageGoToCabinetLink');
            this.messageTitle = document.getElementById('cartMessageTitle');
            this.messageClose = document.getElementById('cartMessageClose');

            this.onHandlerMessageCloseClick = this.handlerMessageCloseClick.bind(this);

            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        this.runButtons.forEach(el => {
            el.addEventListener('click', this.onHandlerRunButtonClick);
        });
        this.messageClose.addEventListener('click', this.onHandlerMessageCloseClick);
    }

    unbind() {
        this.runButtons.forEach(el => {
            el.removeEventListener('click', this.onHandlerRunButtonClick);
        });
        this.messageClose.removeEventListener('click', this.onHandlerMessageCloseClick);
    }

    handlerMessageCloseClick(e) {
        e.preventDefault();
        this.messageTitle.innerText = '';
        this.messageButtonWrap.classList.add('hide');
        this.messageWrap.classList.add('hide');
        this.messageButtonGoToCabinetWrap.classList.add('hide');
    }

    handlerRunButtonClick(e) {
        e.preventDefault();

        if (this.process) {
            return;
        }

        this.pushByUrl(e.target.closest('a').href);
    }

    pushByUrl(url) {
        this.process = true;
        this.pushRequestByUrl(url)
            .then(json => {
                this.messageWrap.classList.remove('hide');
                if (json.success) {
                    this.messageButtonWrap.classList.remove('hide');
                    this.messageTitle.innerText = json.successMessage;
                } else if (json.errorCode === 'exist_in_cart') {
                    this.messageButtonWrap.classList.remove('hide');
                    this.messageTitle.innerText = json.errorMessage;
                } else {
                    this.messageTitle.innerText = json.errorMessage;
                    this.messageButtonGoToCabinetWrap.classList.remove('hide');
                    this.messageButtonGoToCabinetLink.href = json.cabinetUrl;
                }

                if (typeof json.miniCart !== "undefined") {
                    this.miniCartWrap.innerHTML = json.miniCart.html;
                }
            })
            .catch(e => {
                console.error('CART-PUSH-ERROR', e);
                alert('Error! :(');
            })
            .finally(() => {
                this.process = false;
            });
    }

    async pushRequestByUrl(url) {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }
}

export default CartButtonHandler;