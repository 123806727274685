import langCode from "../../translate/lang-code";

class CookieUse {
    constructor() {
        this.isInit = false;
        this.process = false;
    }

    init() {
        if (!this.isInit) {
            this.cookieUse = document.getElementById('cookieUse');
            this.cookieUseClose = document.getElementById('cookieUseClose');

            this.onHandlerCookieUseCloseClick = this.handlerCookieUseCloseClick.bind(this);

            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        this.cookieUseClose.addEventListener('click', this.onHandlerCookieUseCloseClick);
    }

    unbind() {
        this.cookieUseClose.removeEventListener('click', this.onHandlerCookieUseCloseClick);
    }

    handlerCookieUseCloseClick(e) {
        e.preventDefault();
        this.closeCookieUse();
    }

    closeCookieUse() {
        if (this.process) {
            return;
        }

        this.process = true;

        this.closeRequest()
            .then(() => {
                this.process = false;
                this.cookieUse.remove();
            })
            .catch(e => {
                console.error(e);
                this.process = false;
            });
    }

    async closeRequest() {
        const response = await fetch(`/${langCode}/cookie/use`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }
}

export default CookieUse;