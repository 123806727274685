import '../../css/internal/internal.scss';
import copy from 'copy-text-to-clipboard';

class PageRouteInfo {
    constructor() {
        alertify.dialog('pageRouteInfoAlert', () => {
            return {
                setup: function () {
                    return {
                        buttons: [],
                        options: {
                            resizable: false,
                            maximizable: false
                        },
                        transition: 'fade'
                    };
                },
                build: function () {
                    this.elements.root.classList.add('pop_up');
                    this.setHeader(window.locale.router_info.title);
                    this.setContent(`
<div class="bordered">
    <div class="form-group">
        <label>
            <span>${window.locale.router_info.route}</span>
            <input type="text" name="route" value="${window.routeInfo.route}">
        </label>
    </div>
    <div class="form-group form-buttons single">
        <input type="button" class="green" data-target="route" value="${window.locale.router_info.copy}">
    </div>
     <div class="form-group">
        <label>
            <span>${window.locale.router_info.route_with_params}</span>
            <input type="text" name="routeParamsHash" value="${window.routeInfo.routeParamsHash}">
        </label>
    </div>
    <div class="form-group form-buttons single">
        <input type="button" class="green" data-target="routeParamsHash" value="${window.locale.router_info.copy}">
    </div>
</div>
`);
                    this.elements.body.querySelectorAll('input[type=button]').forEach(el => {
                        el.addEventListener('click', e => {
                            const input = e.currentTarget
                                .closest('.bordered')
                                .querySelector(`input[name=${e.currentTarget.dataset.target}]`);
                            copy(input.value);
                            alertify.success(window.locale.router_info.copy_success);
                        })
                    });

                    this.elements.footer.style.display = 'none';
                }
            }
        });

        this.popupInstance = alertify.pageRouteInfoAlert();
        this.isInit = false;
    }

    init() {
        this.btnView = document.getElementById('routeInfoBtn');
        this.onHandlerBtnViewClick = this.handlerBtnViewClick.bind(this);

        if (!this.isInit) {
            this.bind();
            this.isInit = true;
        }
    }

    destroy() {
        if (this.isInit) {
            this.unbind();
            this.isInit = false;
        }

    }

    bind() {
        this.btnView.addEventListener('click', this.onHandlerBtnViewClick);
    }

    unbind() {
        this.btnView.removeEventListener('click', this.onHandlerBtnViewClick);
    }

    show() {
        this.popupInstance.show()
    }

    handlerBtnViewClick(e) {
        e.preventDefault();
        this.show();
    }
}

export default PageRouteInfo;