import langCode from "../../translate/lang-code";
import {calculateTop} from "../utils/LeftSideUtils";

class HelpLeftSideWidget {
    constructor() {
        this.isInit = false;
        this.process = false;
        this.current = {
            catId: 0,
            helpId: 0
        }
    }

    init() {
        if (!this.isInit) {
            this.helpListCategoryName = document.getElementById('helpListCategoryName');
            this.helpListCategoryWrap = document.getElementById('helpListCategoryWrap');
            this.buttonsRun = document.querySelectorAll('[data-left-side-widget="help"]');
            this.helpListWrap = document.getElementById('helpListWrap');
            this.helpListBack = document.getElementById('helpListBack');
            this.helpListClose = document.getElementById('helpListClose');

            this.onHandlerButtonsRunClick = this.handlerButtonsRunClick.bind(this);
            this.onHandlerCategoryClick = this.handlerCategoryClick.bind(this);
            this.onHandlerHelpClick = this.handlerHelpClick.bind(this);
            this.onHandlerHelpListCloseClick = this.handlerHelpListCloseClick.bind(this);
            this.onHandlerHelpListBackClick = this.handlerHelpListBackClick.bind(this);

            this.onHandlerBodyOpenLeftWidgetEvent = this.handlerBodyOpenLeftWidgetEvent.bind(this);

            this.onWindowScrollEvent = this.handlerOnWindowScrollEvent.bind(this);
            this.onWindowRedizeEvent = this.handlerOnWindowResizeEvent.bind(this);

            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        this.helpListWrap.querySelectorAll('h2.title').forEach(el => {
            el.addEventListener('click', this.onHandlerCategoryClick);
        });

        this.buttonsRun.forEach(el => {
            el.addEventListener('click', this.onHandlerButtonsRunClick);
        });

        this.helpListBack.addEventListener('click', this.onHandlerHelpListBackClick);
        this.helpListClose.addEventListener('click', this.onHandlerHelpListCloseClick);
        document.body.addEventListener('OPEN_LEFT_WIDGET', this.onHandlerBodyOpenLeftWidgetEvent);
    }

    unbind() {
        this.helpListWrap.querySelectorAll('h2.title').forEach(el => {
            el.removeEventListener('click', this.onHandlerCategoryClick);
        });

        this.buttonsRun.forEach(el => {
            el.removeEventListener('click', this.onHandlerButtonsRunClick);
        });

        this.helpListBack.removeEventListener('click', this.onHandlerHelpListBackClick);
        this.helpListClose.removeEventListener('click', this.onHandlerHelpListCloseClick);
        document.body.removeEventListener('OPEN_LEFT_WIDGET', this.onHandlerBodyOpenLeftWidgetEvent);
    }

    handlerOnWindowScrollEvent() {
        this.fixPosition();
    }

    handlerOnWindowResizeEvent() {
        this.fixPosition();
    }

    handlerBodyOpenLeftWidgetEvent() {
        this.hide();
    }

    handlerHelpListBackClick(e) {
        e.preventDefault();
        this.categories();
    }

    handlerCategoryClick(e) {
        e.preventDefault();
        this.category(Number(e.currentTarget.dataset.catid))
    }

    handlerHelpClick(e) {
        e.preventDefault();
        e.currentTarget.closest('li').classList.toggle('active');
    }

    categories() {
        this.helpListWrap.querySelectorAll(`h2.title`).forEach(el => {
            el.parentElement.classList.remove('hidden');
            el.parentElement.classList.remove('active');
        });

        this.helpListCategoryName.innerHTML = '';
        this.helpListBack.classList.add('hidden');
    }

    category(catId, helpId) {
        if (this.process) {
            return;
        }

        this.current.catId = catId || 0;
        this.current.helpId = helpId || 0;

        this.process = true;

        const catElement = this.helpListWrap.querySelector(`h2.title[data-catid="${catId}"]`);
        const parentElement = catElement.parentElement;
        const ul = parentElement.querySelector('ul');

        this.helpListCategoryName.innerHTML = catElement.innerHTML;
        this.helpListWrap.querySelectorAll(`h2.title`).forEach(el => {
            if (el.parentElement === parentElement) {
                el.parentElement.classList.remove('hidden');
                el.parentElement.classList.add('active');
            } else {
                el.parentElement.classList.add('hidden');
                el.parentElement.classList.remove('active');
            }
        });

        if (ul.dataset.load === "0") {
            ul.dataset.load = "1";
            this.helpListWrap.classList.add('loading');

            this.load(catId)
                .then(json => {
                    if (json.html) {
                        ul.innerHTML = json.html;
                    }

                    ul.querySelectorAll('h3.title_2').forEach(el => {
                        el.addEventListener('click', this.onHandlerHelpClick);

                        if (helpId && Number(helpId) === Number(el.parentElement.dataset.id)) {
                            el.dispatchEvent(new Event('click'));
                        }
                    });

                    this.fixLinks();
                    this.helpListBack.classList.remove('hidden');
                })
                .catch(e => {
                    console.error(e);
                })
                .finally(() => {
                    this.process = false;
                    this.helpListWrap.classList.remove('loading');
                });
        } else {
            this.process = false;
            this.helpListBack.classList.remove('hidden');

            if (helpId) {
                const activeChildLi = ul.querySelector(`li[data-cid="${helpId}"]`);

                if (activeChildLi) {
                    activeChildLi.querySelector('h3').dispatchEvent(new Event('click'));
                }
            }
        }
    }

    handlerHelpListCloseClick() {
        this.hide();
    }

    handlerButtonsRunClick(e) {
        e.preventDefault();
        if (this.helpListWrap.classList.contains('hidden')) {
            this.show(e.currentTarget.dataset.catid, e.currentTarget.dataset.id);
        } else {
            this.hide();
        }
    }

    show(catId, helpId) {
        const isOpen = !this.helpListWrap.classList.contains('hidden');
        catId = catId ? Number(catId) : 0;
        helpId = helpId ? Number(helpId) : 0;

        if (isOpen && catId && helpId) {
            this.category(catId, helpId);
            return;
        }

        document.body.dispatchEvent(new Event('OPEN_LEFT_WIDGET'));
        document.body.classList.add('withLeftSide');

        window.addEventListener('scroll', this.onWindowScrollEvent);
        window.addEventListener('resize', this.onWindowRedizeEvent);

        this.fixPosition();
        this.fixLinks();
        this.helpListWrap.classList.remove('hidden');

        if (catId && helpId) {
            this.category(catId, helpId);
        } else {
            this.current.catId = 0;
            this.current.helpId = 0;
        }
    }

    fixLinks() {
        this.helpListCategoryWrap.querySelectorAll('a').forEach(a => {
            if (a.target !== '_blank') {
                a.target = '_blank';
            }
        });
    }

    fixPosition() {
        calculateTop(this.helpListWrap);
    }

    hide() {
        if (this.helpListWrap.classList.contains('hidden')) {
            return;
        }

        this.current.catId = 0;
        this.current.helpId = 0;

        document.body.classList.remove('withLeftSide');
        window.removeEventListener('scroll', this.onWindowScrollEvent);
        window.removeEventListener('resize', this.onWindowRedizeEvent);

        this.categories();
        this.process = false;
        this.helpListWrap.classList.add('hidden');
    }

    async load(catId) {
        const response = await fetch(`/${langCode}/_/help/category?f[categoryId]=${catId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }
}

export default HelpLeftSideWidget;