import langCode from "../../translate/lang-code";

class Favorite {
    constructor() {
        this.isInit = false;
        this.process = false;
        this.timerInstance = null;
    }

    init() {
        if (!this.isInit) {
            this.buttonsRun = document.querySelectorAll('[data-favorite-id]');
            this.allFavoriteNotices = document.querySelectorAll('.fav_help');
            this.onHandlerButtonRunClick = this.handlerButtonRunClick.bind(this);

            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        this.buttonsRun.forEach(el => {
            el.addEventListener('click', this.onHandlerButtonRunClick);
        });
    }

    unbind() {
        this.buttonsRun.forEach(el => {
            el.removeEventListener('click', this.onHandlerButtonRunClick);
        });
    }

    handlerButtonRunClick(e) {
        const el = e.currentTarget;
        const entity = el.dataset.favorite;
        const entityId = Number(el.dataset.favoriteId);

        if (!entity || !entityId) {
            return;
        }

        if (el.classList.contains('active')) {
            this.remove(el, entity, entityId);
        } else {
            this.add(el, entity, entityId);
        }
    }

    add(el, entity, entityId) {
        if (this.process) {
            return;
        }

        this.process = true;
        this.addRequest(entity, entityId)
            .then(json => {
                if (json.success) {
                    this.showNotice(el, true);
                    el.classList.add('active');
                    el.title = el.dataset.titleRemove;
                }
            })
            .catch(e => {
                console.error(e);
            })
            .finally(this.timeOut.bind(this));
    }

    remove(el, entity, entityId) {
        if (this.process) {
            return;
        }

        this.process = true;
        this.removeRequest(entity, entityId)
            .then(json => {
                if (json.success) {
                    this.showNotice(el, false);
                    el.classList.remove('active');
                    el.title = el.dataset.titleAdd;
                }
            })
            .catch(e => {
                console.error(e);
            })
            .finally(this.timeOut.bind(this));
    }

    timeOut() {
        setTimeout(() => {
            this.process = false;
        }, 500);
    }

    clearNotice() {
        this.allFavoriteNotices.forEach(el => {
            el.classList.add('hidden');
        });

        if (this.timerInstance) {
            clearTimeout(this.timerInstance);
            this.timerInstance = null;
        }
    }

    showNotice(el, active) {
        this.clearNotice();

        const noticeElement = el.parentElement.querySelector('.fav_help');
        if (noticeElement === null) {
            return;
        }

        noticeElement.innerHTML = noticeElement.dataset[active ? 'remove' : 'add'];
        noticeElement.classList.remove('hidden');

        this.timerInstance = setTimeout(() => {
            noticeElement.classList.add('hidden');
        }, 1200);
    }

    async addRequest(entity, entityId) {
        const response = await fetch(`/${langCode}/favorite/add/${entity}/${entityId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }

    async removeRequest(entity, entityId) {
        const response = await fetch(`/${langCode}/favorite/remove/${entity}/${entityId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }
}

export default Favorite;