import langCode from "../../translate/lang-code";
import {calculateTop} from "../utils/LeftSideUtils";

class NewsLeftSideWidget {
    constructor() {
        this.isInit = false;
        this.isSetRead = false;
        this.process = false;
        this.lastProcessChecked = null;
        this.stateToggleContents = true;

        this.pageNum = 1;
        this.maxPage = 1;
    }

    init() {
        if (!this.isInit) {
            this.buttonsRun = document.querySelectorAll('[data-left-side-widget="news"]');
            this.newsListWrap = document.getElementById('newsListWrap');
            this.newsListContentWrap = document.getElementById('newsListContentWrap');
            this.newsListFilterOnlyProduct = document.getElementById('newsListFilterOnlyProduct');
            this.newsListClose = document.getElementById('newsListClose');
            this.newsListContent = document.getElementById('newsListContent');
            this.newsListToggleContents = document.getElementById('newsListToggleContents');

            this.onHandleNewsListFilterOnlyProduct = this.handleNewsListFilterOnlyProduct.bind(this);
            this.onHandlerNewsListCloseClick = this.handlerNewsListCloseClick.bind(this);
            this.onHandlerButtonsRunClick = this.handlerButtonsRunClick.bind(this);
            this.onHandlerNewsListRowClick = this.handlerNewsListRowClick.bind(this);
            this.onHandlerNewsListToggleContentsClick = this.handlerNewsListToggleContentsClick.bind(this);
            this.onHandlerNewsListMoreClick = this.handlerNewsListMoreClick.bind(this);

            this.onHandlerBodyOpenLeftWidgetEvent = this.handlerBodyOpenLeftWidgetEvent.bind(this);

            this.onWindowScrollEvent = this.handlerOnWindowScrollEvent.bind(this);
            this.onWindowRedizeEvent = this.handlerOnWindowResizeEvent.bind(this);

            this.maxPage = Number(this.newsListWrap.dataset.m);

            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        this.newsListFilterOnlyProduct.addEventListener('change', this.onHandleNewsListFilterOnlyProduct);
        this.newsListClose.addEventListener('click', this.onHandlerNewsListCloseClick);

        this.buttonsRun.forEach(el => {
            el.addEventListener('click', this.onHandlerButtonsRunClick);
        });

        if (this.newsListContent) {
            this.newsListContent.querySelectorAll('li').forEach(el => {
                if (el.classList.contains('more')) {
                    el.addEventListener('click', this.onHandlerNewsListMoreClick);
                } else {
                    el.addEventListener('click', this.onHandlerNewsListRowClick);
                }
            });
        }

        this.newsListToggleContents.addEventListener('click', this.onHandlerNewsListToggleContentsClick);
        document.body.addEventListener('OPEN_LEFT_WIDGET', this.onHandlerBodyOpenLeftWidgetEvent);
    }

    unbind() {
        this.newsListFilterOnlyProduct.removeEventListener('change', this.onHandleNewsListFilterOnlyProduct);
        this.newsListClose.removeEventListener('click', this.onHandlerNewsListCloseClick);

        this.buttonsRun.forEach(el => {
            el.removeEventListener('click', this.onHandlerButtonsRunClick);
        });

        if (this.newsListContent) {
            this.newsListContent.querySelectorAll('li').forEach(el => {
                el.removeEventListener('click', this.onHandlerNewsListRowClick);
            });
        }

        this.newsListToggleContents.removeEventListener('click', this.onHandlerNewsListToggleContentsClick);
        document.body.removeEventListener('OPEN_LEFT_WIDGET', this.onHandlerBodyOpenLeftWidgetEvent);
    }

    handlerNewsListMoreClick(e) {
        e.preventDefault();
        this.pageNum++;
        this.reload();
    }

    handlerOnWindowScrollEvent() {
        this.fixPosition();
    }

    handlerOnWindowResizeEvent() {
        this.fixPosition();
    }

    handlerBodyOpenLeftWidgetEvent() {
        this.hide();
    }

    handlerNewsListToggleContentsClick(e) {
        e.preventDefault();

        if (this.stateToggleContents) {
            this.stateToggleContents = false;
            this.newsListToggleContents.classList.remove('active');
        } else {
            this.stateToggleContents = true;
            this.newsListToggleContents.classList.add('active');
        }

        this.syncStateContents();
    }

    handleNewsListFilterOnlyProduct() {
        const labelCheckbox = this.newsListFilterOnlyProduct
            .closest('.flex')
            .querySelector('.text_switch');

        if (this.newsListFilterOnlyProduct.checked) {
            labelCheckbox.classList.add('checked');
        } else {
            labelCheckbox.classList.remove('checked');
        }

        this.pageNum = 1;
        this.reload();
    }

    handlerNewsListCloseClick() {
        this.hide();
    }

    handlerButtonsRunClick(e) {
        e.preventDefault();
        this.show();
    }

    handlerNewsListRowClick(e) {
        if (e.target.tagName === 'A') {
            return;
        }

        const target = e.currentTarget;

        target.classList.toggle('active');
        target.querySelector('.ico_show_hide').classList.toggle('active');
    }

    syncStateContents() {
        this.newsListContent.querySelectorAll('li.single_news').forEach(el => {
            if (this.stateToggleContents) {
                el.classList.add('active');
                el.querySelector('.ico_show_hide').classList.add('active');
            } else {
                el.classList.remove('active');
                el.querySelector('.ico_show_hide').classList.remove('active');
            }
        });
        this.fixLinks();
    }

    show() {
        if (!this.newsListWrap.classList.contains('hidden')) {
            this.hide();
            return;
        }

        document.body.dispatchEvent(new Event('OPEN_LEFT_WIDGET'));
        document.body.classList.add('withLeftSide');

        window.addEventListener('scroll', this.onWindowScrollEvent);
        window.addEventListener('resize', this.onWindowRedizeEvent);

        this.fixPosition();
        this.fixLinks();
        this.setRead();

        this.newsListWrap.classList.remove('hidden');
    }

    hide() {
        if (this.newsListWrap.classList.contains('hidden')) {
            return;
        }

        document.body.classList.remove('withLeftSide');
        window.removeEventListener('scroll', this.onWindowScrollEvent);
        window.removeEventListener('resize', this.onWindowRedizeEvent);

        this.newsListWrap.classList.add('hidden');
    }

    fixLinks() {
        this.newsListContent.querySelectorAll('a').forEach(a => {
            if (a.target !== '_blank') {
                a.target = '_blank';
            }
        });
    }

    fixPosition() {
        calculateTop(this.newsListWrap);
    }

    reload() {
        if (this.process) {
            return;
        }

        this.process = true;

        this.load()
            .then(json => {
                this.process = false;
                this.destroy();

                document.getElementById('newsListMore')?.parentNode.remove();

                if (this.pageNum > 1) {
                    this.newsListContent.insertAdjacentHTML('beforeend', json.html);
                } else {
                    this.newsListContent.innerHTML = json.html;
                }

                if (this.lastProcessChecked !== this.newsListFilterOnlyProduct.checked) {
                    this.newsListFilterOnlyProduct.checked = this.lastProcessChecked;
                }

                if (this.pageNum === 1) {
                    this.syncStateContents();
                } else {
                    let h = 0;
                    let i = 0;
                    const start = json.pageSize * (this.pageNum - 1);

                    const newsList = this.newsListContent.querySelectorAll('li.single_news');
                    newsList.forEach(el => {
                        if (i < start) {
                            h += el.offsetHeight;
                        }
                        i++;
                    });

                    console.log('H', h);

                    this.newsListContentWrap.scrollTo({
                        top: h + 45,
                        left: 0,
                        behavior: 'smooth'
                    });

                }
                this.init();
            })
            .catch(e => {
                console.error(e);
                this.process = false;
            });
    }

    async load() {
        let url = `/${langCode}/_/news/list/load/page/${this.pageNum}`;
        const onlyProduct = Number(this.newsListFilterOnlyProduct.checked);
        this.lastProcessChecked = this.newsListFilterOnlyProduct.checked;

        if (onlyProduct) {
            url = `${url}?f[onlyProduct]=${onlyProduct}`;
        }

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }

    setRead() {
        if (!window.securityState || this.process || this.isSetRead) {
            return;
        }

        this.process = true;
        this.isSetRead = true;

        this.read()
            .then(() => {
                this.process = false;
            })
            .catch(e => {
                console.error(e);
                this.process = false;
            });
    }

    async read() {
        const response = await fetch(`/${langCode}/_/news/set/read`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }
}

export default NewsLeftSideWidget;