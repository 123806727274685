import langCode from "../../translate/lang-code";
import Timer from "easytimer.js";

class Banner {
    constructor() {
        this.isInit = false;
        this.process = false;
    }

    init() {
        if (!this.isInit) {
            this.buttonCloseRuns = document.querySelectorAll('[data-banner-close]');
            this.onHandlerButtonCloseRunsClose = this.handlerButtonCloseRunsClose.bind(this);

            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        this.buttonCloseRuns.forEach(el => {
            el.addEventListener('click', this.onHandlerButtonCloseRunsClose);
            const bannerWrap = el.closest('.banner');
            const seconds = Number(bannerWrap.dataset.seconds);

            if (!seconds) {
                return;
            }

            const tDay = bannerWrap.querySelector('[data-countdown-day="1"]');
            const tHour = bannerWrap.querySelector('[data-countdown-hour="1"]');
            const tMinute = bannerWrap.querySelector('[data-countdown-minute="1"]');
            const tSecond = bannerWrap.querySelector('[data-countdown-second="1"]');

            const timer = new Timer();
            timer.start({
                countdown: true,
                startValues: {
                    seconds: seconds
                }
            });

            timer.addEventListener('secondsUpdated', () => {
                tDay.innerHTML = timer.getTimeValues().days.toString();
                tHour.innerHTML = timer.getTimeValues().hours.toString();
                tMinute.innerHTML = timer.getTimeValues().minutes.toString();
                tSecond.innerHTML = timer.getTimeValues().seconds.toString();
            });
        });
    }

    unbind() {
        this.buttonCloseRuns.forEach(el => {
            el.removeEventListener('click', this.onHandlerButtonCloseRunsClose);
        });
    }

    handlerButtonCloseRunsClose(e) {
        e.preventDefault();

        const bannerWrap = e.currentTarget.closest('.banner');
        this.closeBanner(bannerWrap.dataset.bannerId);
    }

    closeBanner(id) {
        if (this.process) {
            return;
        }

        this.process = true;

        this.closeRequest(id)
            .then(() => {
                this.process = false;
                const bannerWrap = document.querySelector(`[data-banner-id="${id}"]`)
                bannerWrap.remove();
                document.body.classList.remove('with_banner');
                document.body.classList.remove('with_top_short_banner');
                window.dispatchEvent(new Event('scroll'));
            })
            .catch(e => {
                console.error(e);
                this.process = false;
            });
    }

    async closeRequest(id) {
        const response = await fetch(`/${langCode}/banner/close/${id}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }
}

export default Banner;