import '../css/app.css';
import NewsLeftSideWidget from "./public/news/NewsLeftSideWidget";
import PageRouteInfo from "./iternal/PageRouteInfo";
import HelpLeftSideWidget from "./public/help/HelpLeftSideWidget";
import CabinetMenuToggle from "./iternal/CabinetMenuToggle";
import ApsIdCopy from "./iternal/ApsIdCopy";
import LocaleSwitcher from "./iternal/LocaleSwitcher";

import './external/imgComparisonSlider';
import './external/baguettebox';

import alertify from './external/alertifyjs'
import Banner from "./public/banner/Banner";
import CookieUse from "./public/cookieUse/CookieUse";
import Favorite from "./public/favorite/Favorite";
import Main from "./public/Main";
import DefaultGridLesson from "./public/lesson/DefaultGridLesson";
import DefaultGridAddon from "./public/addon/DefaultGridAddon";
import DefaultGridExtension from "./public/extension/DefaultGridAddon";

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import SecurityForm from "./security/SecurityForm";
import Video from "./public/video/Video";
import Download from "./public/download/Download";
import PreviewLicense from "./public/license/PreviewLicense";
import Comment from "./public/comment/Comment";
import CommentRules from "./public/comment/CommentRules";

import {swiffyslider} from './../../node_modules/swiffy-slider/dist/js/swiffy-slider.esm.min.js'
import './../../node_modules/swiffy-slider/dist/css/swiffy-slider.min.css';
import CartButtonHandler from "./public/order/CartButtonHandler";
import CartHandler from "./public/order/CartHandler";

window.alertify = alertify;
window.tippy = tippy;

if (document.getElementById('newsListWrap')) {
    const newsLeftSideWidget = new NewsLeftSideWidget();
    newsLeftSideWidget.init();
}

if (document.getElementById('helpListWrap')) {
    const helpLeftSideWidget = new HelpLeftSideWidget();
    helpLeftSideWidget.init();
}

if (document.getElementById('routeInfoBtn')) {
    const routeInfo = new PageRouteInfo();
    routeInfo.init();
}

if (document.getElementById('btnMenuToggle')) {
    const cabinetMenuToggle = new CabinetMenuToggle();
    cabinetMenuToggle.init();
}

if (document.getElementById('apsidValue')) {
    const apsIdCopy = new ApsIdCopy();
    apsIdCopy.init();
}

if (document.getElementById('switchLocale')) {
    const localeSwitcher = new LocaleSwitcher();
    localeSwitcher.init();
}

if (document.querySelectorAll('[data-banner-close="1"]')) {
    const banner = new Banner();
    banner.init();
}

if (document.getElementById('cookieUse')) {
    const cookieUse = new CookieUse();
    cookieUse.init();
}

if (document.querySelectorAll('[data-favorite-id]').length) {
    const favorite = new Favorite();
    favorite.init();
}

if (document.getElementById('gridLessonFilterForm')) {
    const defaultGridLesson = new DefaultGridLesson();
    defaultGridLesson.init();
}

if (document.getElementById('gridAddonFilterForm')) {
    const defaultGridAddon = new DefaultGridAddon();
    defaultGridAddon.init();
}

if (document.getElementById('gridExtensionFilterForm')) {
    const defaultGridExtension = new DefaultGridExtension();
    defaultGridExtension.init();
}

if (document.getElementById('securityForm')) {
    const securityForm = new SecurityForm();
    securityForm.init();
    window.securityForm = securityForm;
}

if (document.querySelectorAll('[data-play-video]').length) {
    const video = new Video();
    video.init();
}

if (document.querySelectorAll('[data-download="1"]').length) {
    const download = new Download();
    download.init();
}

if (document.querySelectorAll('[data-license]').length) {
    const previewLicense = new PreviewLicense();
    previewLicense.init();
}

if (document.getElementById('commentRule')) {
    const comment = new Comment(document.getElementById('commMain'));
    comment.init();

    const commentRule = new CommentRules();
    commentRule.init();
}

if (document.querySelectorAll('[data-cart-btn="1"]').length) {
    const cartButtonHandler = new CartButtonHandler();
    cartButtonHandler.init();
}

if (document.getElementById('cartWrap')) {
    const cartHandler = new CartHandler();
    cartHandler.init();
}

const main = new Main();
main.init();

document.addEventListener("DOMContentLoaded", () => {
    tippy('[data-tippy-content]', {
        allowHTML: true
    });
});

if (document.querySelectorAll('.swiffy-slider').length) {
    window.swiffyslider = swiffyslider;
    window.swiffyslider.init();

    document.querySelectorAll('.slider-target').forEach(sl => {
        let index = 0;
        const targetSl = document.getElementById(sl.dataset.target);
        sl.querySelectorAll('li').forEach(li => {
            const img = li.querySelector('img');
            img.dataset.index = String(index);
            img.addEventListener('mouseover', e => {
                swiffyslider.slideTo(targetSl, Number(e.currentTarget.dataset.index))
            });
            index++;
        })
    });
}

if (document.querySelectorAll('.pushToCart').length) {
    const cartBtn = document.querySelector('.btn_price');
    if (cartBtn) {
        document.querySelectorAll('.pushToCart').forEach(el => {
            el.addEventListener('click', e => {
                e.preventDefault();
                cartBtn.click();
            })
        });
    }
}

const galleryAddLineAllElements = document.getElementsByClassName('add_line');
for (let i = 0; i < galleryAddLineAllElements.length; i++) {
    galleryAddLineAllElements[i].addEventListener('click', () => this.classList.toggle('active'));
}

