import Plyr from 'plyr';
import {shadow} from "../utils/Shadow";
import '../../../../node_modules/plyr/dist/plyr.css'
import langCode from "../../translate/lang-code";

class Video {
    constructor() {
        this.isInit = false;
        this.isView = false;
        this.playerInstance = null;
        this.lastContentElement = null;
        this.process = false;
    }

    init() {
        if (!this.isInit) {
            this.playerWrap = document.getElementById('playerWrap');

            this.runButtons = document.querySelectorAll('[data-play-video]');
            this.onHandlerRunButtonClick = this.handlerRunButtonClick.bind(this);
            this.onHandlerBodyKeydown = this.handlerBodyKeyDownEvent.bind(this);
            this.onHandlerWindowResize = this.handlerWindowResize.bind(this);
            this.onHandlerShadowClick = this.handlerShadowClick.bind(this);

            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        this.runButtons.forEach(el => {
            el.addEventListener('click', this.onHandlerRunButtonClick);
        });
    }

    unbind() {
        this.runButtons.forEach(el => {
            el.removeEventListener('click', this.onHandlerRunButtonClick);
        });
    }

    handlerRunButtonClick(e) {
        e.preventDefault();
        this.view(e.currentTarget.dataset, e.currentTarget.closest('.content'));
    }

    handlerBodyKeyDownEvent(e) {
        if (e.keyCode === 27) {
            this.close();
        }
    }

    handlerWindowResize() {
        this.fixSizePositions();
    }

    handlerShadowClick(e) {
        e.preventDefault();
        this.close();
    }

    close() {
        if (!this.isView) {
            return;
        }

        document.removeEventListener('keydown', this.onHandlerBodyKeydown);
        window.removeEventListener('resize', this.onHandlerWindowResize);
        shadow(false, this.onHandlerShadowClick);

        if (this.playerInstance) {
            this.playerInstance.destroy();
            this.playerInstance = null;
            document.getElementById('playerBox').remove();
        }

        this.playerWrap.style.display = 'none';
        this.isView = false;
        this.playerWrap.innerHTML = '';
    }

    view(dataSets, contentElement) {
        this.close();
        let currentPlayerCode = window.playerCode;
        this.playerWrap.innerHTML = '';

        const labels = {
            'youtube': 'Youtube',
            'vk': 'VK Video',
            'rutube': 'Rutube',
            'dzen': 'Дзен'
        };

        const panelButtons = [
            'youtube'
        ];

        if (dataSets.playVkVideo) {
            panelButtons.push('vk');
        }
        if (dataSets.playRutubeVideo) {
            panelButtons.push('rutube');
        }
        if (dataSets.playDzenVideo) {
            panelButtons.push('dzen');
        }

        if (!panelButtons.includes(currentPlayerCode)) {
            currentPlayerCode = 'youtube';
        }

        this.lastContentElement = contentElement;
        this.isView = true;
        document.addEventListener('keydown', this.onHandlerBodyKeydown);
        window.addEventListener('resize', this.onHandlerWindowResize);
        shadow(true, this.onHandlerShadowClick);

        let panel = `<div class="playerHostPanel"><span>Смотреть через:</span><i data-btn-close="1" title="Закрыть окно (ESC)"></i>`

        panelButtons.forEach(playerKey => {
            panel += `<a href="#" data-plc="${playerKey}"${playerKey === currentPlayerCode ? ' class="active"' : ''}>${labels[playerKey]}</a>`
        });

        if (currentPlayerCode === 'vk' && dataSets.playVkVideo) {
            this.playerWrap.innerHTML = `<iframe src="https://vk.com/video_ext.php?oid=-${dataSets.playVkVideo}&autoplay=0" width="100%" height="100%" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>`;
        } else if (currentPlayerCode === 'rutube' && dataSets.playRutubeVideo) {
            this.playerWrap.innerHTML = `<iframe src="https://rutube.ru/play/embed/${dataSets.playRutubeVideo}/" width="100%" height="100%" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>`;
        } else if (currentPlayerCode === 'dzen' && dataSets.playDzenVideo) {
            this.playerWrap.innerHTML = `<iframe src="https://dzen.ru/embed/${dataSets.playDzenVideo}?from_block=partner&from=zen&mute=0&autoplay=0&tv=0" width="100%" height="100%" allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media" frameborder="0" scrolling="no" allowfullscreen></iframe>`;
        } else {
            const playerBox = document.createElement('div');
            playerBox.id = 'playerBox';
            playerBox.dataset.plyrProvider = 'youtube';
            playerBox.dataset.plyrEmbedId = dataSets.playVideo;

            this.playerWrap.appendChild(playerBox);

            this.playerInstance = new Plyr(playerBox, {
                disableContextMenu: true,
                settings: ['captions', 'quality', 'speed', 'loop'],
                youtube: {
                    listType: 'playlist',
                    loop: 1,
                    ecver: 2,
                    modestbranding: 1,
                    rel: 0,
                    showinfo: 0,
                    controls: 1
                }
            });
        }

        this.playerWrap.insertAdjacentHTML('afterbegin', panel)
        this.playerWrap.querySelector('i[data-btn-close="1"]').addEventListener('click', e => {
            e.preventDefault();
            this.close();
        })
        this.playerWrap.querySelectorAll('a[data-plc]').forEach(element => {
            element.addEventListener('click', async e => {
                e.preventDefault();
                await this.handlerSwitchPlayerCode(element.dataset.plc, dataSets, contentElement)
            })
        })

        this.fixSizePositions();
    }

    async handlerSwitchPlayerCode(playerCode, dataSets, contentElement) {
        if (this.process) {
            return;
        }

        this.process = true;
        await this.updatePlayerCode(playerCode);
        this.process = false;

        window.playerCode = playerCode;
        this.view(dataSets, contentElement)
    }

    fixSizePositions() {
        let width = this.lastContentElement.clientWidth;
        let height = 1080 * (width / 1920);
        let separatorLength = 50;

        if (height + separatorLength > document.documentElement.clientHeight) {
            height = document.documentElement.clientHeight - separatorLength;
            width = 1920 * (height / 1080);
        }

        let marginLeft = width / 2;
        let marginTop = height / 2;

        this.playerWrap.style.display = 'block';
        this.playerWrap.style.width = `${width}px`;
        this.playerWrap.style.height = `${height}px`;
        this.playerWrap.style.marginLeft = `-${marginLeft}px`;
        this.playerWrap.style.marginTop = `-${marginTop}px`
    }

    async updatePlayerCode(playerCode) {
        const response = await fetch(`/${langCode}/cookie/player-code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                playerCode: playerCode
            })
        });
        return await response.json();
    }
}

export default Video;