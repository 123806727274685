import langCode from "../../translate/lang-code";

class LicenseAutoPush {
    constructor() {
    }

    async pushRequest(extensionId, version) {
        const response = await fetch(`/${langCode}/extensions/${extensionId}/push/${version}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}
        });
        return await response.json();
    }
}

export default LicenseAutoPush
