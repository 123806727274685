import langCode from "../../translate/lang-code";

class Comment {
    constructor(wrap) {
        this.wrap = wrap;
        this.commInProcess = false;
        this.isInit = false;
    }

    init() {
        if (!this.isInit) {
            this.ulRoot = this.wrap.querySelector('.pub_comm').querySelector('ul[data-level="1"]');
            this.pager = this.wrap.querySelector('.pagination');

            this.onHandlerPagerLinkClick = this.handlerPagerLinkClick.bind(this);

            this.formCreate = this.wrap.querySelector('form[data-comm-act="commFormCreate"]');
            this.formCreateArea = this.wrap.querySelector('textarea[data-comm-act="commAreaCreate"]');
            this.formCreateBtnCreate = this.wrap.querySelector('button[data-comm-act="commRunCreate"]');
            this.formCreateBtnReset = this.wrap.querySelector('button[data-comm-act="commRunReset"]');
            this.formCreateErrorsWrapper = this.wrap.querySelector('div[data-comm-act="commFormErrors"]');

            this.onHandlerCommActAnswerBtnClick = this.handlerCommActAnswerBtnClick.bind(this);
            this.onHandlerRunCommActAnswerBtnClick = this.handlerRunCommActAnswerBtnClick.bind(this);
            this.onHandlerRunCommActCancelBtnClick = this.handlerRunCommActCancelBtnClick.bind(this);

            if (this.formCreate) {
                this.onHandlerFormCreateSubmit = this.handlerFormCreateSubmit.bind(this);
                this.onHandlerFormCreateBtnCreateClick = this.handlerFormCreateBtnCreateClick.bind(this);
                this.onHandlerFormCreateBtnResetClick = this.handlerFormCreateBtnResetClick.bind(this);
            }

            this.isInit = true;
            this.commInProcess = false;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        if (this.formCreate) {
            this.formCreate.addEventListener('submit', this.onHandlerFormCreateSubmit);
            this.formCreateBtnCreate.addEventListener('click', this.onHandlerFormCreateBtnCreateClick);
            this.formCreateBtnReset.addEventListener('click', this.onHandlerFormCreateBtnResetClick);
        }

        if (this.pager) {
            this.pager.querySelectorAll('a').forEach(element => {
                element.addEventListener('click', this.onHandlerPagerLinkClick);
            });
        }

        this.initComm();
    }

    unbind() {
        if (this.formCreate) {
            this.formCreate.removeEventListener('submit', this.onHandlerFormCreateSubmit);
            this.formCreateBtnCreate.removeEventListener('click', this.onHandlerFormCreateBtnCreateClick);
            this.formCreateBtnReset.removeEventListener('click', this.onHandlerFormCreateBtnResetClick);
        }

        if (this.pager) {
            this.pager.querySelectorAll('a').forEach(element => {
                element.removeEventListener('click', this.onHandlerPagerLinkClick);
            });
        }

        this.unbindComm();
    }

    initComm() {
        this.bindComm();
        this.ulRoot.querySelectorAll('.pub_comm_row').forEach(row => {
            this.rowPrepareText(row);
        });
    }

    bindComm() {
        this.ulRoot.querySelectorAll('a[data-comm-act="answer"]').forEach(element => {
            if (!element.dataset.bind) {
                element.dataset.bind = 'yes';
                element.addEventListener('click', this.onHandlerCommActAnswerBtnClick);
            }
        });
    }

    unbindComm() {
        this.ulRoot.querySelectorAll('a[data-comm-act="answer"]').forEach(element => {
            element.removeEventListener('click', this.onHandlerCommActAnswerBtnClick);
        });
    }

    handlerPagerLinkClick(e) {
        e.preventDefault();
        if (this.commInProcess) {
            return;
        }
        this.paginationHandleUrl(e.target.href);
    }

    handlerFormCreateBtnCreateClick(e) {
        e.preventDefault();
        if (this.commInProcess) {
            return;
        }
        this.submit();
    }

    handlerFormCreateBtnResetClick(e) {
        e.preventDefault();
        this.reset();
    }

    handlerFormCreateSubmit(e) {
        e.preventDefault();

        if (this.commInProcess) {
            return;
        }

        this.submit();
    }

    handlerCommActAnswerBtnClick(e) {
        e.preventDefault();

        if (this.commInProcess) {
            return;
        }

        this.beginAnswer(e.target);
    }

    handlerRunCommActAnswerBtnClick(e) {
        e.preventDefault();
        const area = this.ulRoot.querySelector('.commAreaAnswer');
        const message = String(area.value).trim();

        if (message === '') {
            this.closeAnswer();
            return;
        }

        this.commInProcess = true;
        this.answer(Number(area.dataset.id), message);
    }

    handlerRunCommActCancelBtnClick(e) {
        e.preventDefault();
        this.closeAnswer();
    }

    paginationHandleUrl(url) {
        this.commInProcess = true;
        this.wrap.classList.add('pub_comm_loading');

        this.paginationRequest(url)
            .then(json => {
                this.commInProcess = false;
                if (json.success) {
                    this.destroy();
                    this.wrap.innerHTML = '';
                    this.wrap.innerHTML = json.html;
                    this.wrap.classList.remove('pub_comm_loading');
                    this.wrap.scrollIntoView({block: 'start', behavior: 'smooth'});
                    this.init();
                }
            })
            .catch(() => {
                window.location.reload();
            });
    }

    async paginationRequest(url) {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }

    reset() {
        this.formCreateErrorsWrapper.innerHTML = '';
        this.formCreateArea.value = '';
        this.formCreateArea.focus();
    }

    submit() {
        this.formCreateErrorsWrapper.innerHTML = '';
        const message = String(this.formCreateArea.value).trim();
        if (message === '') {
            return;
        }

        this.commInProcess = true;

        this.submitRequest(message)
            .then(json => {
                this.commInProcess = false;
                if (json.success) {
                    this.insertNew(json.newCommId, json.html);
                } else {
                    this.formCreateErrorsWrapper.innerHTML = json.error;
                }
            })
            .catch(() => {
                window.location.reload();
            });
    }

    insertNew(newCommId, htmlNewComment) {
        this.formCreateArea.value = '';

        this.ulRoot.insertAdjacentHTML('afterbegin', htmlNewComment);
        this.initComm();

        const newRow = this.getRow(newCommId);
        newRow.scrollIntoView({block: 'center', behavior: 'smooth'});
        newRow.classList.add('pub_comm_new_row');

        setTimeout(() => {
            newRow.classList.remove('pub_comm_new_row');
        }, 10000);
    }

    async submitRequest(message) {
        const module = Number(this.formCreate.dataset.module);
        const entity = this.formCreate.dataset.entity;
        const entityId = Number(this.formCreate.dataset.entityid);
        const response = await fetch(`/${langCode}/_/comments/create/${module}/${entity}/${entityId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                message: message
            })
        });
        return await response.json();
    }

    answer(commId, message) {
        this.answerRequest(commId, message)
            .then(json => {
                const errWrapper = this.ulRoot.querySelector('.commErrorAnswer');
                this.commInProcess = false;
                if (json.success) {
                    this.insertAnswer(commId, json.newCommId, json.html);
                } else {
                    errWrapper.innerHTML = json.error;
                }
            })
            .catch(() => {
                window.location.reload();
            });
    }

    insertAnswer(commId, newCommId, htmlNewComment) {
        this.closeAnswer();

        const row = this.getRow(commId);
        const rowLevel = Number(row.closest('ul').dataset.level);
        const nextLevel = rowLevel + 1;

        let childWrapper = row.querySelector(`ul[data-level="${nextLevel}"]`);

        if (childWrapper === null) {
            childWrapper = document.createElement('ul');
            childWrapper.dataset.level = String(nextLevel);
            row.appendChild(childWrapper);
        }

        childWrapper.insertAdjacentHTML('afterend', htmlNewComment);
        this.initComm();

        const newRow = this.getRow(newCommId);
        newRow.scrollIntoView({block: 'center', behavior: 'smooth'});
        newRow.classList.add('pub_comm_new_row');

        setTimeout(() => {
            newRow.classList.remove('pub_comm_new_row');
        }, 10000);
    }

    getRow(commId) {
        return this.ulRoot.querySelector(`li.pub_comm_row[data-id="${commId}"]`);
    }

    async answerRequest(commId, message) {
        const response = await fetch(`/${langCode}/_/comments/answer/${commId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                message: message
            })
        });
        return await response.json();
    }

    closeAnswer() {
        this.ulRoot.querySelectorAll('.pub_comm_row_answer_active').forEach(row => {
            row.querySelector('.pub_comm_row_answer').innerHTML = '';
            row.classList.remove('pub_comm_row_answer_active');
        });
    }

    beginAnswer(targetElement) {
        this.closeAnswer();

        const row = targetElement.closest('.pub_comm_row');
        const answerWrapper = row.querySelector('.pub_comm_row_answer');

        row.classList.remove('pub_comm_new_row');

        answerWrapper.innerHTML = `<div class="errors" class="commErrorAnswer"></div>
            <textarea class="commAreaAnswer" data-id="${row.dataset.id}"></textarea>
            <a href="javascript:void(0)" class="btn green">${targetElement.dataset.langAnswer}</a>
            <a href="javascript:void(0)" class="btn gray">${targetElement.dataset.langCancel}</a>`;

        answerWrapper.querySelector('a.green').addEventListener('click', this.onHandlerRunCommActAnswerBtnClick);
        answerWrapper.querySelector('a.gray').addEventListener('click', this.onHandlerRunCommActCancelBtnClick);

        row.classList.add('pub_comm_row_answer_active');
        this.ulRoot.querySelector('.commAreaAnswer').focus();
    }

    rowPrepareText(row) {
        if (row.dataset.prepare) {
            return;
        }

        row.dataset.prepare = 'yes';
        const messageWrapper = row.querySelector('.pub_comm_row_body_message');
        let message = messageWrapper.innerHTML;

        message = message.replaceAll(
            /(\b(https?):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/ig,
            "<a href='$1' target='_blank'>$1</a>"
        );

        messageWrapper.innerHTML = message;
    }
}

export default Comment;