import langCode from "../translate/lang-code";

class Token {
    constructor() {
        this.isInit = false;
    }

    init() {
        if (!this.isInit) {
            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.process = false;
            this.unbind();
        }
    }

    bind() {

    }

    unbind() {

    }

    async load(tokenId) {
        const formData = new FormData();
        formData.append('tokenId', tokenId);

        const response = await fetch(`/${langCode}/_/token/refresh`, {
            method: 'POST',
            body: formData
        });
        return await response.json();
    }
}

export default Token;