class PreviewLicense {
    constructor() {
        this.isInit = false;
    }

    init() {
        if (!this.isInit) {
            this.runButtons = document.querySelectorAll('[data-license]');
            this.dwnButtons = document.querySelectorAll('[data-dwn]');
            this.onHandlerRunButtonClick = this.handlerRunButtonClick.bind(this);
            this.onHandlerDwnButtonClick = this.handlerDwnButtonClick.bind(this);

            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        this.runButtons.forEach(el => {
            el.addEventListener('click', this.onHandlerRunButtonClick);
        });
        this.dwnButtons.forEach(el => {
            el.addEventListener('click', this.onHandlerDwnButtonClick);
        });
    }

    unbind() {
        this.runButtons.forEach(el => {
            el.removeEventListener('click', this.onHandlerRunButtonClick);
        });
        this.dwnButtons.forEach(el => {
            el.removeEventListener('click', this.onHandlerDwnButtonClick);
        });
    }

    handlerDwnButtonClick(e) {
        e.preventDefault();
        const licenseType = e.currentTarget.dataset.dwn;
        const bl = document.querySelector('[data-license-cnt="' + licenseType + '"]');
        if (bl) {
            bl.querySelector('a').click();
        } else {
            console.error(`Button by licenceType=${licenseType} not found.`)
        }
    }

    handlerRunButtonClick(e) {
        e.preventDefault();
        const licenseType = e.currentTarget.dataset.license;
        const parent = e.currentTarget.closest('.preview') ?
            e.currentTarget.closest('.preview') :
            e.currentTarget.closest('.license_switcher');

        if (parent === null) {
            return;
        }

        parent.querySelectorAll('[data-license-cnt]').forEach(el => {
            const cntLicenseType = el.dataset.licenseCnt;

            if (cntLicenseType === licenseType) {
                el.classList.remove('hide');
            } else {
                el.classList.add('hide');
            }
        });

        parent.querySelectorAll('[data-license]').forEach(el => {
            const cntLicenseType = el.dataset.license;

            if (cntLicenseType === licenseType) {
                el.classList.add('active');
            } else {
                el.classList.remove('active');
            }
        });
    }
}

export default PreviewLicense;