class DefaultGridLesson {
    constructor() {
        this.isInit = false;
        this.process = false;
    }

    init() {
        if (!this.isInit) {
            this.gridFilterForm = document.getElementById('gridLessonFilterForm');
            this.paginatorValue = document.getElementById('paginatorValue');
            this.pageSize = document.getElementById('f_pageSize');

            this.onHandlerPaginatorValueChange = this.handlerPaginatorValueChange.bind(this);

            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        if (this.paginatorValue && this.pageSize) {
            this.paginatorValue.addEventListener('change', this.onHandlerPaginatorValueChange);
        }
    }

    unbind() {
        if (this.paginatorValue && this.pageSize) {
            this.paginatorValue.removeEventListener('change', this.onHandlerPaginatorValueChange);
        }
    }

    handlerPaginatorValueChange() {
        this.syncPageSizeValue();
    }

    syncPageSizeValue() {
        this.pageSize.value = this.paginatorValue.value;
        this.gridFilterForm.submit();
    }
}

export default DefaultGridLesson;