class CommentRules {
    constructor() {
        this.isInit = false;
        this.popupInstance = null;
    }

    init() {
        if (!this.isInit) {
            this.runButton = document.getElementById('commentRule');
            this.onHandlerRunButtonClick = this.handlerRunButtonClick.bind(this);

            this.isInit = true;
            this.registerPopup();
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.commInProcess = false;

            this.unbind();
            this.popupInstance.destroy();
            this.popupInstance = null;
        }
    }

    bind() {
        this.runButton.addEventListener('click', this.onHandlerRunButtonClick);
    }

    unbind() {
        this.runButton.removeEventListener('click', this.onHandlerRunButtonClick);
    }

    handlerRunButtonClick(e) {
        e.preventDefault();
        this.popupInstance.show();
    }

    registerPopup() {
        const _self = this;

        alertify.dialog('commentRule', () => {
            return {
                setup: function () {
                    return {
                        buttons: [
                            {
                                text: window.locale.popups.comment.btn,
                                className: 'green',
                                scope: 'default',
                                invokeOnClose: true,
                                cancel: true,
                                key: 27,
                            }
                        ],
                        focus: {},
                        options: {
                            resizable: false,
                            maximizable: false
                        },
                        transition: 'fade'
                    };
                },
                callback: (closeEvent) => {
                    if (closeEvent.button.key === 13) {
                        window.location.href = _self.fileData.link;
                    }
                },
                prepare: function () {
                    this.setHeader(window.locale.popups.comment.title);
                    this.setContent(window.locale.popups.comment.message);
                },
                build: function () {
                    this.elements.root.classList.add('pop_up');
                    this.elements.root.classList.add('pop_up_comments');

                    this.elements.footer.querySelector('.ajs-primary').classList.add('form-group');
                    this.elements.footer.querySelector('.ajs-primary').classList.add('form-buttons');
                }
            }
        });

        this.popupInstance = alertify.commentRule();
    }
}

export default CommentRules;