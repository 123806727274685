import copy from "copy-text-to-clipboard";

class ApsIdCopy {
    constructor() {
        this.isInit = false;
    }

    init() {
        if (!this.isInit) {
            this.apsidValue = document.getElementById('apsidValue');
            this.onHandlerApsidValueClick = this.handlerApsidValueClick.bind(this);

            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        this.apsidValue.addEventListener('click', this.onHandlerApsidValueClick);
    }

    unbind() {
        this.apsidValue.removeEventListener('click', this.onHandlerApsidValueClick);
    }

    handlerApsidValueClick(e) {
        e.preventDefault();
        this.copy();
    }

    copy() {
        copy(this.apsidValue.innerText);
        alertify.success(window.locale.apsid.copy_success);
    }
}

export default ApsIdCopy;