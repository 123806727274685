class LocaleSwitcher {
    constructor() {
        this.isInit = false;
    }

    init() {
        if (!this.isInit) {
            this.switchLocale = document.getElementById('switchLocale');
            this.switchLocaleItems = document.getElementById('switchLocaleItems');
            this.switchLocaleClose = document.getElementById('switchLocaleClose');

            this.onHandlerSwitchLocaleClick = this.handlerSwitchLocaleClick.bind(this);
            this.onHandlerSwitchLocaleClick = this.handlerSwitchLocaleClick.bind(this);
            this.onHandlerSwitchLocaleCloseCLick = this.handlerSwitchLocaleCloseCLick.bind(this);

            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        this.switchLocale.addEventListener('click', this.onHandlerSwitchLocaleClick);
        this.switchLocaleClose.addEventListener('click', this.onHandlerSwitchLocaleCloseCLick);
    }

    unbind() {
        this.switchLocale.removeEventListener('click', this.onHandlerSwitchLocaleClick);
        this.switchLocaleClose.removeEventListener('click', this.onHandlerSwitchLocaleCloseCLick);
    }

    handlerSwitchLocaleCloseCLick(e) {
        e.preventDefault();
        this.switchLocaleItems.classList.toggle('active');
    }

    handlerSwitchLocaleClick(e) {
        e.preventDefault();
        // this.switchLocaleItems.classList.toggle('active');
    }
}

export default LocaleSwitcher;