import validator from "validator";
import langCode from "../translate/lang-code";
import Token from "../iternal/Token";

class SecurityForm {
    constructor() {
        this.isInit = false;
        this.isInitView = false;
        this.invalid = false;
        this.process = false;
        this.scenaName = null;

        this.SCENARIO_NAMES = {
            LOGIN: 'login',
            REGISTER: 'register',
            RECOVERY: 'recovery',
            RESEND: 'resend'
        };

        this.scenarioConfig = {
            login: {
                url: `/${langCode}/login`,
                usePassword: true,
                tokenKey: 'tokenLogin',
                tokenId: 'authenticate',
                locale: {
                    globalKey: 'loginForm',
                    placeholderKey: 'loginPlaceholder',
                    submitKey: 'loginSubmit'
                }
            },
            register: {
                url: `/${langCode}/register`,
                usePassword: true,
                successMessage: true,
                tokenKey: 'tokenRegister',
                tokenId: 'register',
                locale: {
                    globalKey: 'registerForm',
                    placeholderKey: 'registerPlaceholder',
                    submitKey: 'registerSubmit'
                }
            },
            resend: {
                url: `/${langCode}/register/resend`,
                usePassword: false,
                successMessage: true,
                tokenKey: 'tokenResend',
                tokenId: 'resend.register',
                locale: {
                    globalKey: 'resendForm',
                    placeholderKey: 'resendPlaceholder',
                    submitKey: 'resendSubmit'
                }
            },
            recovery: {
                url: `/${langCode}/recovery-password/post`,
                usePassword: false,
                successMessage: true,
                tokenKey: 'tokenRecovery',
                tokenId: 'recovery.password',
                locale: {
                    globalKey: 'recoveryForm',
                    placeholderKey: 'recoveryPlaceholder',
                    submitKey: 'recoverySubmit'
                }
            }
        };

        this.popupInstance = null;
        this.refreshToken = new Token();
    }

    init() {
        if (this.isInit) {
            return;
        }

        this.buttonsRunScenarios = document.querySelectorAll('[data-security-run]');
        this.onHandlerBtnRunScenario = this.handlerBtnRunScenario.bind(this);

        this.isInit = true;
        this.factoryAlertify();
        this.bind();

        const parseUrl = new URL(window.location.href);
        if (parseUrl.hash && parseUrl.hash !== '') {
            const parseHash = parseUrl.hash.match(/security-(login|register)/);
            if (parseHash !== null) {
                this.runScenario(parseHash[1]);
            }
        }
    }

    destroy() {
        if (!this.isInit) {
            return;
        }

        this.isInit = false;
        this.destroyAlertify();
        this.unbind();
    }

    displayScenario() {
        this.reset();
        const scenarioConfig = this.scenarioConfig[this.scenaName];

        this.preEmailMessages.forEach(el => {
            if (el.dataset.preTarget === this.scenaName) {
                el.style.display = 'block';
            }
        });

        this.popupInstance.elements.header.innerHTML = window.locale[scenarioConfig.locale.globalKey].form.title;

        this.scenaSwitcherControls.forEach(el => {
            if (el.dataset.link === this.scenaName) {
                el.classList.add('active');
            }
        });

        this.submit.value = this.submit.dataset[scenarioConfig.locale.submitKey];
        this.email.placeholder = this.email.dataset[scenarioConfig.locale.placeholderKey];

        if (scenarioConfig.usePassword) {
            this.password.placeholder = this.password.dataset[scenarioConfig.locale.placeholderKey];
            this.passwordRow.style.display = 'block';
        } else {
            this.passwordRow.style.display = 'none';
        }

        this.email.focus();
    }

    initView() {
        if (!this.isInitView) {
            const cntRoot = this.popupInstance.elements.content;

            this.form = cntRoot.querySelector('form');
            this.emailRow = cntRoot.querySelector('[data-row="email"]');
            this.passwordRow = cntRoot.querySelector('[data-row="password"]');
            this.submitRow = cntRoot.querySelector('[data-row="button"]');
            this.messagesSuccessRow = cntRoot.querySelector('[data-row="message.success"]');
            this.controlsRow = cntRoot.querySelector('.controls');

            this.email = cntRoot.querySelector('input[name="email"]');
            this.password = cntRoot.querySelector('input[name="password"]');
            this.submit = cntRoot.querySelector('input[type="submit"]');
            this.errorBoxEmail = cntRoot.querySelector('.error[data-target="email"]');
            this.errorBoxPassword = cntRoot.querySelector('.error[data-target="password"]');

            this.scenaSwitcherControls = this.controlsRow
                .querySelectorAll('a');

            this.preEmailMessages = this.emailRow
                .querySelectorAll('span[data-pre-target]');

            this.successMessages = this.messagesSuccessRow
                .querySelectorAll('div[data-target]');

            this.onHandlerSubmitResolve = this.handlerSubmitResolve.bind(this);
            this.onHandlerScenaSwitcherControlsClick = this.handlerScenaSwitcherControlsClick.bind(this);

            this.bindView();
            this.isInitView = true;

            this.displayScenario();
        }
    }

    destroyView() {
        if (this.isInitView) {
            this.unbindView();
            this.isInitView = false;
            this.invalid = false;
            this.process = false;
            this.scenaName = null;

            this.onHandlerSubmitResolve = null;
            this.onHandlerScenaSwitcherControlsClick = null;
        }
    }

    bind() {
        this.buttonsRunScenarios.forEach(el => {
            el.addEventListener('click', this.onHandlerBtnRunScenario);
        });
    }

    unbind() {
        this.buttonsRunScenarios.forEach(el => {
            el.removeEventListener('click', this.onHandlerBtnRunScenario);
        });
    }

    bindView() {
        this.submit.addEventListener('click', this.onHandlerSubmitResolve);

        this.scenaSwitcherControls.forEach(el => {
            el.addEventListener('click', this.onHandlerScenaSwitcherControlsClick);
        });
    }

    unbindView() {
        this.submit.removeEventListener('click', this.onHandlerSubmitResolve);

        this.scenaSwitcherControls.forEach(el => {
            el.removeEventListener('click', this.onHandlerScenaSwitcherControlsClick);
        });
    }

    handlerScenaSwitcherControlsClick(e) {
        e.preventDefault();
        this.reset();
        this.runScenario(e.currentTarget.dataset.link);
    }

    handlerSubmitResolve(e) {
        e.preventDefault();
        this.submitForm();
    }

    handlerBtnRunScenario(e) {
        e.preventDefault();
        this.runScenario(e.currentTarget.dataset.securityRun)
    }

    reset() {
        this.preEmailMessages.forEach(el => {
            el.style.display = 'none';
        });
        this.messagesSuccessRow.style.display = 'none';
        this.successMessages.forEach(el => {
            el.style.display = 'none';
        });
        this.scenaSwitcherControls.forEach(el => {
            el.classList.remove('active');
        });

        this.email.value = '';
        this.password.value = '';

        this.errorBoxPassword.innerHTML = '';
        this.errorBoxEmail.innerHTML = '';
        this.invalid = false;

        this.emailRow.style.display = 'block';
        this.passwordRow.style.display = 'none';
        this.submitRow.style.display = 'block';
        this.messagesSuccessRow.style.display = 'none';
        this.controlsRow.style.display = 'block';
    }

    runScenario(name) {
        if (name === this.scenaName) {
            return;
        }

        this.scenaName = name;

        if (this.popupInstance.isOpen()) {
            this.displayScenario();
        } else {
            this.popupInstance.show();
        }
    }

    resolveRegisterErrors(json) {
        if (json.error) {
            this.errorBoxEmail.innerHTML = json.error;
            this.email.focus();
        } else {
            Object.keys(json.errors).forEach(k => {
                const er = json.errors[k][0];

                switch (k) {
                    default:
                    case 'email':
                        this.errorBoxEmail.innerHTML = er;
                        this.email.focus();
                        break;

                    case 'password':
                        this.errorBoxPassword.innerHTML = er;
                        this.password.focus();
                        break;
                }
            });
        }
    }

    submitForm() {
        if (this.process || !this.validate()) {
            return;
        }

        const scenarioConfig = this.scenarioConfig[this.scenaName];
        this.process = true;

        this.request()
            .then(async json => {
                if (json.systemCode !== undefined && json.systemCode === 'token') {
                    await this.refreshToken.load(scenarioConfig.tokenId)
                        .then(json => {
                            this.form.dataset[scenarioConfig.tokenKey] = json.token;
                            setTimeout(() => {
                                this.process = false;
                                this.submitForm();
                            }, 1500);
                        })
                        .catch(() => {
                            window.location.reload();
                        });
                    return;
                }

                this.process = false;

                if (json.errors || json.error) {
                    this.invalid = true;
                    this.resolveRegisterErrors(json);
                } else {
                    if (scenarioConfig.successMessage) {
                        this.emailRow.style.display = 'none';
                        this.passwordRow.style.display = 'none';
                        this.submitRow.style.display = 'none';
                        this.messagesSuccessRow.style.display = 'block';
                        this.controlsRow.style.display = 'none';

                        this.successMessages.forEach(el => {
                            if (el.dataset.target === this.scenaName) {
                                el.style.display = 'block';
                            }
                        });

                    } else {
                        if (this.scenaName === this.SCENARIO_NAMES.LOGIN && document.URL.match(/recovery-password/i)) {
                            window.location.href = this.form.dataset.baseUrl;
                        } else {
                            window.location.reload();
                        }
                    }
                }
            })
            .catch((e) => {
                console.log('CATCH-REDIRECT', e);
                window.location.reload();
            })
    }

    async request() {
        const scenarioConfig = this.scenarioConfig[this.scenaName];

        if (this.scenaName === this.SCENARIO_NAMES.LOGIN) {
            const form = new FormData();
            form.append('email', this.email.value.trim());
            form.append('password', this.password.value.trim());
            form.append('_remember_me', '1');
            form.append('_token', this.form.dataset[scenarioConfig.tokenKey]);

            const response = await fetch(scenarioConfig.url, {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: form
            });

            return await response.json();
        } else {
            let body = {
                email: this.email.value.trim(),
                _token: this.form.dataset[scenarioConfig.tokenKey]
            };

            if (scenarioConfig.usePassword) {
                body.password = this.password.value.trim();
            }

            const response = await fetch(scenarioConfig.url, {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            return await response.json();
        }
    }

    validate() {
        this.clearErrors();

        const scenarioConfig = this.scenarioConfig[this.scenaName];
        const email = String(this.email.value).trim();

        if (validator.isEmpty(email)) {
            this.errorBoxEmail.innerHTML = window.locale[scenarioConfig.locale.globalKey].errors.email.empty;
            this.invalid = true;
            this.email.focus();
        } else if (!validator.isEmail(email)) {
            this.errorBoxEmail.innerHTML = window.locale[scenarioConfig.locale.globalKey].errors.email.invalid;
            this.invalid = true;
            this.email.focus();
        }

        if (scenarioConfig.usePassword) {
            const password = String(this.password.value).trim();

            if (validator.isEmpty(password)) {
                this.errorBoxPassword.innerHTML = window.locale[scenarioConfig.locale.globalKey].errors.password.empty;
                if (!this.invalid) {
                    this.password.focus();
                }
                this.invalid = true;
            } else if (!validator.isLength(password, {min: 6})) {
                this.errorBoxPassword.innerHTML = window.locale[scenarioConfig.locale.globalKey].errors.password.short;
                if (!this.invalid) {
                    this.password.focus();
                }
                this.invalid = true;
            }
        }

        return !this.invalid;
    }

    clearErrors() {
        this.invalid = false;
        this.errorBoxEmail.innerHTML = '';
        this.errorBoxPassword.innerHTML = '';
    }

    factoryAlertify() {
        if (this.popupInstance) {
            return;
        }

        const _self = this;

        alertify.dialog('securityForms', () => {
            return {
                setup: function () {
                    return {
                        buttons: [],
                        focus: {
                            element: function () {
                                return this.elements.body.querySelector('input[name="email"]');
                            },
                            select: true
                        },
                        options: {
                            resizable: false,
                            maximizable: false
                        },
                        transition: 'fade'
                    };
                },
                prepare: function () {
                    const scenarioConfig = _self.scenarioConfig[_self.scenaName];
                    this.setHeader(window.locale[scenarioConfig.locale.globalKey].form.title);
                },
                build: function () {
                    this.elements.root.classList.add('pop_up');
                    this.elements.root.classList.add('pop_security');
                    this.elements.footer.style.display = 'none';
                    this.setContent(document.getElementById('securityForm').querySelector('.alertify-content'));
                },
                hooks: {
                    onshow: () => {
                        _self.initView();
                    },
                    onclose: () => {
                        _self.destroyView();
                    }
                }
            }
        });

        this.popupInstance = alertify.securityForms();
    }

    destroyAlertify() {
        if (this.popupInstance) {
            this.destroyView();
            this.popupInstance.destroy();
            this.popupInstance = null;
            alertify.securityForms = undefined;
        }
    }
}

export default SecurityForm;