import LicenseAutoPush from "../extension/LicenseAutoPush";

class Download {
    constructor() {
        this.isInit = false;
        this.popupInstance = null;
        this.fileData = {
            link: null,
            filename: null
        };
        this.licenseAutoPush = new LicenseAutoPush();
    }

    init() {
        if (!this.isInit) {
            this.runButtons = document.querySelectorAll('[data-download="1"]');
            this.onHandlerRunButtonClick = this.handlerRunButtonClick.bind(this);

            this.isInit = true;
            this.registerPopup();
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;

            this.unbind();
            this.popupInstance.destroy();

            this.popupInstance = null;
            this.fileData.filename = null;
            this.fileData.link = null;
        }
    }

    bind() {
        this.runButtons.forEach(el => {
            el.addEventListener('click', this.onHandlerRunButtonClick);
        });
    }

    unbind() {
        this.runButtons.forEach(el => {
            el.removeEventListener('click', this.onHandlerRunButtonClick);
        });
    }

    handlerRunButtonClick(e) {
        e.preventDefault();
        this.fileData.link = e.currentTarget.href;
        this.fileData.filename = e.currentTarget.dataset.filename;

        if (e.currentTarget.dataset.demoExtensionId || e.currentTarget.dataset.trialExtensionId) {
            // Регистрация DEMO/TRIAL лицензии в аккаунте покупателя перед началом скачивания файла
            this.licenseAutoPush.pushRequest(
                e.currentTarget.dataset.demoExtensionId ?
                    e.currentTarget.dataset.demoExtensionId :
                    e.currentTarget.dataset.trialExtensionId,
                e.currentTarget.dataset.demoExtensionId ?
                    'demo' :
                    'trial'
            ).then(json => {
                if (json.success) {
                    this.popupInstance.show();
                } else {
                    alert('ERROR: #972');
                }
            });
        } else {
            this.popupInstance.show();
        }
    }

    registerPopup() {
        const _self = this;

        alertify.dialog('downloadFile', () => {
            return {
                setup: function () {
                    return {
                        buttons: [
                            {
                                text: window.locale.popups.downloads.btn,
                                className: 'green',
                                scope: 'default',
                                invokeOnClose: false,
                                key: 13,
                            }
                        ],
                        focus: {},
                        options: {
                            resizable: false,
                            maximizable: false
                        },
                        transition: 'fade'
                    };
                },
                callback: (closeEvent) => {
                    if (closeEvent.button.key === 13) {
                        window.location.href = _self.fileData.link;
                    }
                },
                prepare: function () {
                    this.setHeader(window.locale.popups.downloads.title.replace('{filename}', _self.fileData.filename));
                    this.setContent(window.locale.popups.downloads.message);
                },
                build: function () {
                    this.elements.root.classList.add('pop_up');
                    this.elements.root.classList.add('pop_up_confirm');
                    this.elements.root.classList.add('pop_up_download');

                    this.elements.footer.querySelector('.ajs-primary').classList.add('form-group');
                    this.elements.footer.querySelector('.ajs-primary').classList.add('form-buttons');
                    this.elements.footer.querySelector('.ajs-primary').classList.add('centered');
                }
            }
        });

        this.popupInstance = alertify.downloadFile();
    }
}

export default Download;