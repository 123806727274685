export const shadow = (status, handler) => {
    const shadow = document.getElementById('shadow');

    if (status) {
        if (typeof handler === "function") {
            shadow.addEventListener('click', handler);
        }
        document.body.classList.add('shadow');
    } else {
        if (typeof handler === "function") {
            shadow.removeEventListener('click', handler);
        }
        document.body.classList.remove('shadow');
    }

    shadow.style.display = status ? 'block' : 'none';
};