import langCode from "../../translate/lang-code";

class CarHandler {
    constructor() {
        this.process = false;
        this.isInit = false;
        this.initBackUrlValue = '';
    }

    init() {
        if (!this.isInit) {
            this.onHandlerRunButtonClick = this.handlerRunButtonClick.bind(this);
            this.onHandlerCartRunClick = this.handlerCartRunClick.bind(this);
            this.onHandlerFormSubmit = this.handlerFormSubmit.bind(this);
            this.onHandlerUsePointsChange = this.handlerUsePointsChange.bind(this);
            this.onHandlerChangePaymentId = this.handlerChangePaymentId.bind(this);

            this.wrap = document.getElementById('cartWrap');
            this.cartProcess = document.getElementById('cartProcess');
            this.form = document.getElementById('orderForm');
            if (this.form) {
                this.paymentId = document.getElementById('f_paymentId');
                this.paymentIdRadio = this.form.payment;
                if (this.initBackUrlValue === '') {
                    this.initBackUrlValue = document.getElementById('backUrl').href;
                }
            }
            this.runButtons = document.querySelectorAll('[data-cart-act]');
            this.cartRun = document.getElementById('cartRun');
            this.usePoints = document.getElementById('f_usePoints');
            this.formUsePoints = document.getElementById('f_canUseUserPoints');

            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        this.runButtons.forEach(el => {
            if (el.dataset.bind) {
                return;
            }
            el.dataset.bind = "1";
            el.addEventListener('click', this.onHandlerRunButtonClick);
        });


        if (this.cartRun) {
            this.paymentIdRadio.forEach(radio => {
                radio.addEventListener('change', this.onHandlerChangePaymentId);
            });

            this.cartRun.addEventListener('click', this.onHandlerCartRunClick);
            this.form.addEventListener('submit', this.onHandlerFormSubmit);
            this.usePoints.addEventListener('change', this.onHandlerUsePointsChange);
        }
    }

    unbind() {
        this.runButtons.forEach(el => {
            if (el.dataset.bind) {
                return;
            }
            el.dataset.bind = "1";
            el.removeEventListener('click', this.onHandlerRunButtonClick);
        });

        if (this.cartRun) {
            this.paymentIdRadio.forEach(radio => {
                radio.removeEventListener('change', this.onHandlerChangePaymentId);
            });

            this.cartRun.removeEventListener('click', this.onHandlerCartRunClick);
            this.form.removeEventListener('submit', this.onHandlerFormSubmit);
            this.usePoints.removeEventListener('change', this.onHandlerUsePointsChange);
        }
    }

    handlerChangePaymentId() {
        this.paymentId.value = this.paymentIdRadio.value;
    }

    handlerUsePointsChange() {
        this.reload();
    }

    handlerCartRunClick(e) {
        e.preventDefault();
        this.form.dispatchEvent(new Event('submit', {
            cancelable: true
        }));
    }

    handlerFormSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.process) {
            return false;
        }

        this.submit();
        return false;
    }

    handlerRunButtonClick(e) {
        if (this.process) {
            e.preventDefault();
            return;
        }
        const el = e.target.closest('[data-cart-act]');
        if (el.dataset.cartAct === 'remove') {
            e.preventDefault();
            this.remove(el.dataset.entity, Number(el.dataset.id));
        } else {
            throw new Error(`Unsupported action by name ${el.dataset.cartAct}.`);
        }
    }

    reload() {
        if (this.process) {
            return;
        }

        this.process = true;
        this.block(true);

        this.reloadRequest(this.isUsePoint(), this.getSid())
            .then(this.afterRequest.bind(this))
            .catch(this.catchRequest.bind(this))
            .finally(this.finallyRequest.bind(this));
    }

    submit() {
        if (this.process) {
            return;
        }

        this.submitRequest()
            .then(json => {
                if (json.errorCode) {
                    alert(json.errorMessage);
                    window.location.reload();
                } else if (typeof json.paymentUrl !== "undefined") {
                    window.location.href = json.paymentUrl;
                }
            })
            .catch(this.catchRequest.bind(this))
            .finally(this.finallyRequest.bind(this))
    }

    isUsePoint() {
        return (this.usePoints !== null && this.usePoints.checked)
    }

    getSid() {
        return document.getElementById('f_sid').value;
    }

    catchRequest(e) {
        console.error('CART-REMOVE-ERROR', e);
        alert('Error! :(');
        window.location.reload();
    }

    afterRequest(json) {
        if (json.errorCode) {
            alert(json.errorMessage);
            window.location.reload();
        } else if (typeof json.cart !== "undefined") {
            this.reHtml(json.cart.html);
            this.wrap.dataset.sid = json.cart.sid;
        }
    }

    finallyRequest() {
        this.process = false;
        this.block(false);
    }

    remove(entity, entityId) {
        this.process = true;
        this.block(true);

        this.removeRequest(entity, entityId, this.isUsePoint(), this.getSid())
            .then(this.afterRequest.bind(this))
            .catch(this.catchRequest.bind(this))
            .finally(this.finallyRequest.bind(this));
    }

    reHtml(newContent) {
        this.destroy();
        document.getElementById('cartWrap').innerHTML = newContent;
        this.init();
        document.getElementById('backUrl').href = this.initBackUrlValue;
    }

    block(status) {
        if (status) {
            this.cartProcess.classList.remove('hide');
        } else {
            this.cartProcess.classList.add('hide');
        }
    }

    async reloadRequest(usePoints, sid) {
        const response = await fetch(`/${langCode}/cart/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                usePoints: Number(usePoints),
                sid: sid
            })
        });
        return await response.json();
    }

    async removeRequest(entity, entityId, usePoints, sid) {
        const response = await fetch(`/${langCode}/cart/remove/${entity}/${entityId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                usePoints: Number(usePoints),
                sid: sid
            })
        });
        return await response.json();
    }

    async submitRequest() {
        const response = await fetch(this.form.getAttribute('action'), {
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams([...new FormData(this.form).entries()])
        });
        return await response.json();
    }
}

export default CarHandler;