import langCode from "../translate/lang-code";

class CabinetMenuToggle {
    constructor() {
        this.isInit = false;
        this.process = false;
    }

    init() {
        if (!this.isInit) {
            this.btnMenuToggle = document.getElementById('btnMenuToggle');
            this.cabinetMenuSide = document.getElementById('cabinetMenuSide');

            this.onHandlerBtnMenuToggleClick = this.handlerBtnMenuToggleClick.bind(this);

            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        this.btnMenuToggle.addEventListener('click', this.onHandlerBtnMenuToggleClick);
    }

    unbind() {
        this.btnMenuToggle.removeEventListener('click', this.onHandlerBtnMenuToggleClick);
    }

    handlerBtnMenuToggleClick(e) {
        e.preventDefault();

        if (this.process) {
            return;
        }

        this.process = true;

        this.btnMenuToggle.classList.toggle('active');
        this.cabinetMenuSide.classList.toggle('compact');
        const value = Number(this.btnMenuToggle.classList.contains('active'));

        this.setValue(value)
            .catch(e => {
                console.error(e);
            })
            .finally(() => {
                this.process = false;
            });
    }

    async setValue(value) {
        const response = await fetch(`/${langCode}/control/cabinet-menu-toggle/${value}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }
}

export default CabinetMenuToggle;