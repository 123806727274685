class Main {
    constructor() {
        this.isInit = false;
    }

    init() {
        if (!this.isInit) {
            this.fixed = document.getElementById('fixed');
            this.fixedTitle = document.getElementById('fixed_title');
            this.mobNav = document.getElementById('mobNav');
            this.switchLocaleItems = document.getElementById('switchLocaleItems');

            this.onHandlerWindowScroll = this.handlerWindowScroll.bind(this);
            this.onHandlerMobNavClick = this.handlerMobNavClick.bind(this);

            this.isInit = true;
            this.bind();

            if (document.body.classList.contains('with_banner') ||
                document.body.classList.contains('with_top_short_banner')) {
                this.fixHeaderWithTopBanner();
            }
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        if (document.body.classList.contains('with_banner') ||
            document.body.classList.contains('with_top_short_banner')) {
            window.addEventListener('scroll', this.onHandlerWindowScroll);
        }

        if (this.mobNav) {
            this.mobNav.addEventListener('click', this.onHandlerMobNavClick);
        }
    }

    unbind() {
        window.removeEventListener('scroll', this.onHandlerWindowScroll);

        if (this.mobNav) {
            this.mobNav.removeEventListener('click', this.onHandlerMobNavClick);
        }
    }

    handlerMobNavClick(e) {
        e.preventDefault();
        e.currentTarget.classList.toggle('active');
    }

    handlerWindowScroll() {
        this.fixHeaderWithTopBanner();
    }

    fixHeaderWithTopBanner() {
        const banner = document.getElementById('topBanner');
        if (banner === null) {
            return;
        }

        if (window.scrollY > banner.offsetHeight) {
            this.fixed.classList.add('sticky');
            if (this.fixedTitle) {
                this.fixedTitle.classList.add('sticky');
            }
            this.switchLocaleItems.classList.add('sticky');
        } else {
            this.fixed.classList.remove('sticky');
            if (this.fixedTitle) {
                this.fixedTitle.classList.remove('sticky');
            }
            this.switchLocaleItems.classList.remove('sticky');
        }
    }
}

export default Main;